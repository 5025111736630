import * as React from 'react';
import Link from 'gatsby-link';
import styled from "styled-components";

const SidebarContainer = styled.div`
    margin-top: 10px;
    width: 250px;
    min-width: 250px;
    header {
        font-weight: bold;
        text-decoration: uppercase;
        margin: 0px 0px 8px 8px;
        a {
          color: ${props => props.theme.color.primary};
          text-decoration: none;
        }
    }
    ul {
        margin: 0 0 16px 0;
        a {
          color: ${props => props.theme.color.primary};
          text-decoration: none;
        }
    }
`;

const Sidebar = () => (
    <SidebarContainer>
        <header><Link to="/">Home</Link></header>
        <header>Quick start</header>
        <ul>
            <li><Link to="/about">What is Ergo Wallet</Link></li>
            <li><Link to="/getting-started">Getting Started</Link></li>
        </ul>
        <header>Ergo Platform</header>
        <ul>
            <li><Link to="/what-is-ergo">Overview</Link></li>
            <li><Link to="/keys-and-addresses">Keys and addresses</Link></li>
            <li><Link to="/tokens">Native Tokens</Link></li>
        </ul>
        <header>Other</header>
        <ul>
            <li><Link to="/magnum-wallet-recover">Magnum Wallet</Link></li>
        </ul>
        <header>Help & Feedback</header>
        <ul>
            <li><Link to="/contact-us">Contact us</Link></li>
        </ul>
    </SidebarContainer>
);

export default Sidebar;
