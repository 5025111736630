import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from 'styled-components'
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import Container from "../components/Container";

const CardContainer = styled.div`
  display: flex;
  margin: 32px 0;
  justify-content: space-around;
`
const Card = styled(Link)`
  display: inline-block;
  border-radius: 4px;
  padding: 20px 40px;
  width: 250px;
  background-color: #aaa;
  color: #fff !important;
  text-align: center;
`

const StyledContainer = styled(Container)`
    display: flex;
    position: relative;
    
    @media (max-width: ${props => props.theme.screen.md}) {
    }
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <Navigation />
      <StyledContainer>
          <Sidebar />
          <div>
              <h1>Welcome to Ergo Wallet Knowledge Base</h1>
              {/*<CardContainer>*/}
              {/*    <Card>Help</Card>*/}
              {/*    <Card>Contact</Card>*/}
              {/*</CardContainer>*/}
              <p>A lot of useful information will be provided for you soon</p>
          </div>
      </StyledContainer>
  </Layout>
)

export default IndexPage
