import * as React from 'react';
import styled from "styled-components";
import Container from "./Container";

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Nav = styled.nav`
  padding: ${props => (props.scrolled ? `16px 0` : `24px 0`)};
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${props => (props.scrolled ? `white` : null)};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`


export const Brand = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${props => props.theme.color.primary};
      text-decoration: none;
    }
  }
`

function Navigation() {
    return (
        <Nav>
            <StyledContainer>
                <Brand>
                    <ul>
                        <a href="https://ergowallet.io" title={"Go to ergowallet.io"}>Ergo Wallet</a>
                    </ul>
                </Brand>
            </StyledContainer>
        </Nav>
    );
}

export default Navigation;
